import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import media from "styled-media-query";

import Btn from '@components/common/btn';
import { breakpoint, color } from "@src/settings";
import { Contact }  from './svgs';
import { Cont, Frame, Title, Txts } from './styles';

const ContactIntro = (props) => {
	return (
		<Cont className={ props.className }>
			<Frame>
				<Title>
					<h2><Contact /></h2>
					<p>お問い合わせ</p>
				</Title>
				<Txts>
					<h3>どんなことでもまずはお気軽にお問い合わせください。</h3>
					<p>お問い合わせは以下のメールフォームよりご連絡ください。<br/>スタッフより迅速に対応させていただきます。</p>
					<Btn className="btn" to="/contact" />
				</Txts>
			</Frame>
		</Cont>
	);
}

export default ContactIntro;

