import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/common/layout';
import Hero from '@components/common/hero';
import ContactIntro from '@components/common/contact-intro';;
import { News } from '@components/news/svgs';
import { TitleCont, Title, PostDate, NewsListBox, Post } from './styles';
import { color } from '@src/settings';

const PostDetail = ({ data }) => {
	return (
		<Layout baseBgColor={ color.paleGray }>
			<Hero
				img={ data["heroImg"] }
				title={<News style={{ fill: "#FFF" }}/> }
				subTitle="お知らせ"
			/>
			<NewsListBox btnLabel="記事一覧">
				<TitleCont>
					<Title dangerouslySetInnerHTML={{ __html: data.post.title }} />
					<PostDate dangerouslySetInnerHTML={{ __html: data.post.date }} />
				</TitleCont>

				<Post dangerouslySetInnerHTML={{ __html: data.post.content }} />
			</NewsListBox>
			<ContactIntro />
		</Layout>
	)
}

export default PostDetail;

export const pageQuery = graphql`
	query($id: String!){

		post: wordpressPost(id: { eq: $id}){
			title
			date(formatString: "Y.M.D")
			content
		},
		heroImg: file( relativePath: { eq: "news/hero-img_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
	}
`;
